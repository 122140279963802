/*!
 * Bootstrap Grid v4.0.0-beta.2 (https://getbootstrap.com)
 * Copyright 2011-2017 The Bootstrap Authors
 * Copyright 2011-2017 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @at-root {
    @-ms-viewport {
        width: device-width;
    }

    // stylelint-disable-line at-rule-no-vendor-prefix
}

html {
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
}

@import "bootstrap/functions";
@import "bootstrap/variables";

//
// Grid mixins
//

@import "bootstrap/mixins/breakpoints";
@import "bootstrap/mixins/grid-framework";
@import "bootstrap/mixins/grid";

@import "bootstrap/grid";
@import "bootstrap/utilities/display";
@import "bootstrap/utilities/flex";


.d-blog{
    padding: 55px 0;
    padding-bottom: 70px;
    &__title{
        font-size: 70px;
        color: $primary-color;
        @include font-weight( 'normal' );
        text-align: center;
        margin: 0;
        margin-bottom: 70px;
    }
    &__form{
        margin-bottom: 80px;
    }
    &__col{
        margin-bottom: 35px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    &__btn{
        display: block;
        width: 200px;
        height: 50px;
        line-height: 45px;
        margin: 0 auto;
        margin-top: 70px;
        text-align: center;
        font-size: 18px;
        border: 2px solid $second-color;
        &:hover{
            background: $second-color;
            color: $white-color;
        }
    }
    &-item{
        &_size{
            &-m{
                height: 100%;
                &:hover{
                    background: $primary-color;
                    & .d-blog-item{
                        &__title{
                            color: $white-color;
                            text-decoration: none;
                        }
                        &__img{
                            &-btn{
                                opacity: 1;
                            }
                            &::after{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
            &-s{
                &:hover{
                    & .d-blog-item{
                        &__img-btn_size-s{
                            opacity: 1;
                        }
                        &__img{
                            &::after{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
        &__img{
            width: 100%;
            height: 300px;
            display: block;
            background-size: cover;
            background-position: 50%;
            position: relative;
            &::after{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba( $black-color, .4 );
                top: 0;
                left: 0;
                opacity: 0;
                transition: opacity .2s ease;
            }
            &-btn{
                width: 100%;
                max-width: 280px;
                height: 50px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -25px;
                margin-left: -140px;
                font-size: 18px;
                line-height: 45px;
                color: $white-color;
                text-align: center;
                border: 2px solid $second-color;
                opacity: 0;
                transition: opacity .2s ease;
                z-index: 1;
                &_size{
                    &-s{
                        border-color: $white-color;
                        max-width: 200px;
                        margin-left: -100px;
                    }
                }
            }
            &_size{
                &-l{
                    height: 440px;
                }
                &-s{
                    height: 200px;
                }
            }
        }
        &__title{
            margin: 0;
            font-size: 26px;
            color: $primary-color;
            text-decoration: none;
            &_size{
                &-l{
                    font-size: 40px;
                }
                &-m{
                    font-size: 20px;
                }
                &-s{
                    font-size: 20px;
                }
            }
            &:hover{
                color: $second-color;
            }
        }
        &__desc{
            margin-top: 25px;
            & p{
                margin: 0;
                font-size: 18px;
                line-height: 1.42;
                margin-bottom: 15px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        &__tags{
            margin-top: 30px;
            @include flex( row, center, flex-start );
            flex-wrap: wrap;
            & a {
                text-decoration: none;
                font-size: 13px;
                color: $second-color;
                margin-right: 15px;
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        &__date{
            margin: 0;
            font-size: 15px;
            color: $dark-color;
            margin-top: 15px;
        }
        &__grid{
            &_size{
                &-m{
                    padding: 20px 15px;
                }
                &-s{
                    padding: 15px 0;
                }
            }
        }
    }
    &__section{
        margin-top: 80px;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

//Media
@media screen and ( max-width: 1199px ){
    .d-blog{
        &__col{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}
@media screen and ( max-width: 991px ){
    .d-blog{
        &__title{
            font-size: 45px;
            margin-bottom: 50px;
        }
        &__col{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &-item{
            &__title{
                &_size{
                    &-l{
                        margin-top: 15px;
                    }
                }
            }
        }
    }
}
@media screen and ( max-width: 767px ){
    .d-blog{
        &__title{
            font-size: 40px;
            margin-bottom: 50px;
        }
        &__form{
            margin-bottom: 50px;
        }
        &__section{
            margin-top: 50px;
        }
        &__col{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__btn{
            margin-top: 50px;
        }
        &-item{
            &__img{
                height: 300px;
                &-btn{
                    max-width: 200px;
                    margin-left: -100px;
                }
                &_size{
                    &-l{
                        height: 350px;
                    }
                    &-s{
                        height: 250px;
                    }
                }
            }
            &__title{
                font-size: 25px;
                &_size{
                    &-l{
                        font-size: 30px;
                        margin-top: 15px;
                    }
                    &-s{
                        font-size: 23px;
                    }
                }
            }
            &__desc{
                margin-top: 15px;
                & p{
                    font-size: 16px;
                }
            }
        }
    }
}
@media screen and ( max-width: 575px ){
    .d-blog{
        &__title{
            font-size: 30px;
            margin-bottom: 50px;
        }
        &__form{
            margin-bottom: 50px;
        }
        &__section{
            margin-top: 50px;
        }
        &__col{
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__btn{
            margin-top: 50px;
        }
        &-item{
            &__img{
                height: 250px;
                &-btn{
                    max-width: 200px;
                    margin-left: -100px;
                }
                &_size{
                    &-l{
                        height: 300px;
                    }
                    &-s{
                        height: 200px;
                    }
                }
            }
            &__title{
                font-size: 23px;
                &_size{
                    &-l{
                        font-size: 28px;
                        margin-top: 10px;
                    }
                    &-s{
                        font-size: 20px;
                    }
                }
            }
            &__desc{
                margin-top: 15px;
                & p{
                    font-size: 16px;
                }
            }
        }
    }
}
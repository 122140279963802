// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

@font-face {
    font-family: 'Century Gothic';
    src: font('CenturyGothic-Bold.woff2') format('woff2'),
        font('CenturyGothic-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Century Gothic';
    src: font('CenturyGothic.woff2') format('woff2'),
        font('CenturyGothic.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


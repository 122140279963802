// -----------------------------------------------------------------------------
// This file contains all application-wide Sass variables.
// -----------------------------------------------------------------------------
//Font
$font: 'Century Gothic';
$base-font-size: 16px;
//Url
$base-path: '../';
//Colors
$white-color: #fff;
$black-color: #191A22;
$gray-color: #f7f7f7;
$dark-color: #4d4d4d;
$primary-color: #1D1924;
$second-color: #E6125D;
//Font weight
$font-weight: (
    'normal': normal,
    'bold': bold
);

.d-article{
    &-header{
        padding-top: 45px;
        overflow: hidden;
        position: relative;
        &__container{
            position: relative;
            z-index: 2;
        }
        &__info{
            margin: 0;
            @include font-weight( 'bold' );
            padding-top: 20px;
            padding-left: 80px;
            @include flex( row, center, space-between );
        }
        &__img{
            width: 100%;
            max-width: 600px;
            position: absolute;
            top: 0;
            right: 60%;
            z-index: 1;
        }
    }
    &__title{
        background: $gray-color;
        padding: 80px;
        padding-right: 0;
        margin: 0;
        font-size: 55px;
        line-height: 1.1;
        @include font-weight( 'normal' );
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 100%;
            top: 0;
            background: $gray-color;
        }
    }
    &__content{
        padding: 55px 0;
        padding-top: 80px;
        & p{
            margin: 0;
            font-size: 18px;
            line-height: 1.45;
            margin-bottom: 25px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        & ul, ol{
            list-style: none;
            padding: 0;
            margin: 0;
            margin-bottom: 25px;
            position: relative;
            &:last-child{
                margin-bottom: 0;
            }
            & li{
                font-size: 18px;
                line-height: 1.45;
                margin-bottom: 8px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
        & ul{
            & li{
                &::before{
                    content: '- ';
                }
            }
        }
        & ol{
            & li{
                &::before{
                    counter-increment: item;                 /* Инкрементирует счетчик*/
                    content: counter(item) ") ";
                }
            }
        }
    }
    &__back-link{
        margin-top: 50px;
        @include flex( row, center, flex-start );
        font-size: 18px;
        text-decoration: none;
        & svg{
            margin-right: 10px;
        }
    }
}

//Media
@media screen and ( max-width: 991px ){
    .d-article{
        &__title{
            background: transparent;
            padding: 0;
            font-size: 30px;
            &::after{
                content: none;
            }
        }
        &-header{
            padding-top: 30px;
            &__img{
                display: none;
            }
            &__info{
                padding-left: 0;
                font-size: 15px;
            }
        }
        &__content{
            padding-top: 50px;
        }
    }
}
@media screen and ( max-width: 575px ){
    .d-article{
        &__content{
            & p{
                font-size: 15px;
            }
            & ul, ol{
                & li{
                    font-size: 15px;
                }
            }
        }
        &__title{
            font-size: 25px;
        }
        &-header{
            &__info{
                font-size: 14px;
            }
        }
    }
}
.d-tags{
    @include flex( row, center, flex-start );
    flex-wrap: wrap;
    &__item{
        text-decoration: none;
        font-size: 14px;
        height: 40px;
        line-height: 40px;
        padding: 0 15px;
        background: $gray-color;
        margin-right: 10px;
        margin-bottom: 5px;
        &:hover{
            background: $second-color;
            color: $white-color;
        }
        &:last-child{
            margin-right: 0;
        }
    }
}
.d-case-item{
    width: 25%;
    height: 45vw;
    background-size: cover;
    background-position: right center;
    position: relative;
    overflow: hidden;
    &__info{
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 35px;
        transform: translateY(10px);
        transition: transform .2s linear;
    }
    &:hover{
        & .d-case-item{
            &__info{
                transform: translateY(0);
                background: $primary-color;
            }
        }
    }
    &__name{
        margin: 0;
        font-size: 45px;
        color: $white-color;
    }
    &__title{
        margin: 0;
        font-size: 20px;
        color: $white-color;
        line-height: 1.2;
        margin-top: 5px;
    }
    &__type{
        margin: 0;
        color: $white-color;
        font-size: 18px;
        margin-top: 15px;
        @include font-weight( 'light' );
    }
    &__btn{
        margin-top: 30px;
        height: 50px;
        width: 100%;
        max-width: 200px;
        @include flex( row, center, center );
        border: 2px solid $second-color;
        color: $white-color;
        font-size: 18px;
        & svg{
            margin-left: 5px;
            position: relative;
            top: 1px;
        }
        &:hover{
            background: $second-color;
            & svg{
                & path{
                    fill: $white-color;
                }
            }
        }
    }
}

//Media
@media screen and ( max-width: 1400px ){
    .d-case-item{
        &__info{
            padding: 35px 25px;
        }
        &__name{
            font-size: 35px;
        }
    }
}

@media screen and ( max-width: 1199px ){
    .d-case-item{
        width: 33.333%;
        height: 55vw;
    }
}

@media screen and ( max-width: 991px ){
    .d-case-item{
        height: 700px;
    }
}

@media screen and ( max-width: 767px ){
    .d-case-item{
        width: 50%;
        height: 600px;
        &__name{
            font-size: 30px;
        }
    }
}

@media screen and ( max-width: 575px ){
    .d-case-item{
        width: 100%;
        height: 500px;
        &__info{
            transform: translateY(0);
            background: $primary-color;
        }
        &__name{
            font-size: 30px;
        }
    }
}
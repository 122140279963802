// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

.link{
    position: relative;
    text-decoration: none;
    line-height: 1.3;
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: $black-color;
        transform: scale3d(1,1,1);
        transition: transform .2s cubic-bezier(0.215, 0.610, 0.355, 1);
    }
    &:hover{
        &::after{
            transform: scale3d(0,1,1);
        }
    }
}

.touchevents{
    & .link{
        &::after{
            content: none;
        }
    }
}

.title{
    margin: 0;
    font-size: 24px;
    text-decoration: none;
    @include font-weight('light');
}
.d-case{
    &-header{
        padding: 95px 0;
        padding-bottom: 195px;
        overflow: hidden;
        position: relative;
        background: $gray-color;
        &__img{
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
            width: 1130px;
            right: 5%;
            &-inner{
                position: absolute;
                z-index: 2;
                top: 3.6%;
                width: 935px;
                right: 9.08%;
            }
        }
        &__container{
            position: relative;
            z-index: 2;
        }
        &__info{
            @include flex( column, flex-start, flex-start );
            background: $primary-color;
            list-style: none;
            margin: 0;
            padding: 0;
            padding: 35px 0;
            padding-bottom: 45px;
            padding-right: 10px;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                right: 100%;
                top: 0;
                background: $primary-color;
            }
        }
        &__item{
            margin-bottom: 25px;
            font-size: 24px;
            color: $second-color;
            line-height: 1.2;
            & img{
                flex-shrink: 0;
                margin-left: 30px;
            }
            & p{
                @include flex( row, center, flex-start );
                margin: 0;
                margin-top: 5px;
                font-size: 20px;
                color: $white-color;
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
        &__link{
            @include flex( row, center, flex-start );
            text-decoration: none;
            font-size: 20px;
            color: $second-color;
            & svg{
                margin-right: 10px;
            }
            &:hover{
                color: darken( $second-color, 5% );
            }
        }
    }
    &-desc{
        padding: 65px 0;
        padding-bottom: 90px;
        &__text{
            margin-top: 30px;
            padding-right: 50px;
            & p{
                margin: 0;
                font-size: 18px;
                line-height: 1.42;
                margin-bottom: 20px;
                &:last-child{
                    margin-bottom: 0;
                }
            }
        }
    }
    &__title{
        margin: 0;
        font-size: 36px;
        @include font-weight( 'normal' );
    }
    &-quote{
        @include flex( column, center, flex-start );
        &__img{
            max-width: 145px;
        }
        &__name{
            margin: 0;
            text-align: center;
            font-size: 26px;
            line-height: 1.3;
            & span{
                display: block;
                font-size: 18px;
            }
        }
    }
    &-img{
        border-top: 150px solid $primary-color;
        &__content{
            box-shadow: 0 22px 30px 0 rgba($black-color, .09);
            max-width: 80%;
            margin: 0 auto;
            position: relative;
            top: -100px;
        }
    }
    &-footer{
        padding-bottom: 50px;
        &__grid{
            @include flex( column, center, flex-start );
            & img{
                max-width: 200px;
            }
        }
        &__link{
            @include flex( row, center, center );
            font-size: 20px;
            margin-top: 20px;
            text-decoration: none;
            & svg{
                flex-shrink: 0;
                margin-left: 10px;
            }
        }
        &__title{
            margin: 0;
            font-size: 25px;
        }
    }
}

//Media
@media screen and ( max-width: 1229px ){
    .d-case-header{
        &__img{
            right: -15%;
        }
    }
}
@media screen and ( max-width: 991px ){
    .d-case{
        &-desc{
            padding: 50px 0;
            &__text{
                padding-right: 0;
                font-size: 16px;
            }
            &__quote{
                margin-top: 50px;
            }
        }
        &__title{
            font-size: 30px;
        }
        &-quote{
            &__name{
                font-size: 18px;
                & span{
                    font-size: 15px;
                }
            }
        }
        &-footer{
            & img{
                max-width: 150px;
            }
            &__title{
                font-size: 18px;
            }
            &__link{
                font-size: 18px;
                margin-top: 40px;
            }
        }
        &-header{
            padding-top: 0;
            padding-bottom: 30px;
            &__info{
                &::after{
                    content: '';
                    position: absolute;
                    height: 100%;
                    width: 100%;
                    left: 100%;
                    top: 0;
                    background: $primary-color;
                }
            }
            &__img{
                position: relative;
                transform: none;
                top: auto;
                right: auto;
            }
        }
    }
}
@media screen and ( max-width: 767px ){
    .d-case-header{
        &__item{
            font-size: 20px;
            & p{
                font-size: 18px;
            }
        }
        &__img{
            width: 500px;
            margin: 0 auto;
            margin-top: 20px;
            &-inner{
                width: 414px;
            }
        }
    }
}
@media screen and ( max-width: 575px ){
    .d-case{
        &-header{
            &__item{
                & p{
                    flex-direction: column;
                    align-items: flex-start;
                    & img{
                        margin-left: 0;
                        margin-top: 5px;
                    }
                }
            }
        }
        &-img{
            padding: 0 15px;
            &__content{
                width: 100%;
                max-width: none;
            }
        }
        &-desc{
            &__text{
                & p{
                    font-size: 15px;
                }
            }
        }
        &-footer{
            & img{
                max-width: 100px;
            }
        }
    }
}
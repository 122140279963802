.d-sort-form{
    @include flex( row, center, flex-start );
    &__wrapper{
        @include flex( row, center, flex-start );
        flex-wrap: wrap;
        margin-right: 10px;
    }
    &__checkbox{
        margin: 5px 0;
        margin-right: 10px;
        &:last-child{
            margin-right: 0;
        }
    }
    &__btn{
        margin-left: auto;
        font-size: 18px;
        color: $dark-color;
        border-bottom: 1px solid $dark-color;
        flex-shrink: 0;
        &:hover{
            color: darken($dark-color, 5%);
        }
    }
}

//Media
@media screen and ( max-width: 767px ){
    .d-sort-form{
        &__btn{
            font-size: 15px;
        }
    }
}
@media screen and ( max-width: 575px ){
    .d-sort-form{
        flex-direction: column;
        align-items: flex-start;
        &__wrapper{
            flex-wrap: wrap;
        }
        &__btn{
            margin-left: 0;
            font-size: 14px;
            margin-top: 10px;
        }
        &__checkbox{
            margin-right: 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
}
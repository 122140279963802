.d-sort-checkbox{
    position: relative;
    display: block;
    &__input{
        position: absolute;
        width: 0;
        height: 0;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        &:checked{
            & + .d-sort-checkbox{
                &__name{
                    background: $second-color;
                    color: $white-color;
                }
            }
        }
    }
    &__name{
        margin: 0;
        padding: 0 10px;
        height: 40px;
        line-height: 40px;
        background: $gray-color;
        font-size: 14px;
        cursor: pointer;
        &:hover{
            background: $second-color;
            color: $white-color;
        }
        &:active{
            background: darken($second-color, 5%);
            color: $white-color;
        }
    }
}

//Media
@media screen and ( max-width: 767px ){
    .d-sort-checkbox{
        &__name{
            font-size: 13px;
        }
    }
}
@media screen and ( max-width: 575px ){
    .d-sort-checkbox{
        &__name{
            margin-bottom: 5px;
        }
    }
}